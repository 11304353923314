<template>
    <div>
        <v-btn color="success" @click="addNewUser">
            Add User <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-container color="primary">
            <v-card icon="clipboard-text" title="Users" class="px-5 py-10">
                <v-card-title>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
                    </v-text-field>
                </v-card-title>
                <v-data-table dense :headers="newFields" ref="usersList" :items="items" :search="search" class="elevation-1"
                    expand="true">
                    <template v-slot:[`header.name`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.arabicName`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.userName`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.password`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.mobile`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.gender`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.joinDate`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.eMail`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.notes`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.User_Rights`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:[`header.Action`]="{ header }">
                        <h6><b> {{ header.text }}</b> </h6>
                    </template>
                    <template v-slot:top>
                        <v-dialog v-model="userRolesDialog" max-width="400">
                            <v-card>
                                <v-toolbar color="green" dark>
                                    <v-toolbar-title>User Roles</v-toolbar-title>
                                </v-toolbar>
                                <v-form ref="EditUserRolesForm" v-model="valid" lazy-validation>
                                    <v-list-item-group v-model="model">
                                        <v-list-item v-for="(role, index) in UserRolesList" :key="index">
                                            <v-list-item-content>
                                                <v-checkbox v-model="editedItem.selectedRoles" :label="role.text"
                                                    color="success" :value=role.value></v-checkbox>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-form>
                                <v-row>
                                    <v-progress-linear :active="loading" color="green" :indeterminate="loading" absolute
                                        bottom height="15">
                                        Loading...
                                    </v-progress-linear>
                                </v-row>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="green darken-1" text @click="UpdateUserRoles">Update</v-btn>
                                    <v-btn color="green darken-1" text @click="userRolesDialog = false">Cancel</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="Editdialog" max-width="800px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Edit User Details</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form ref="EditUserInfoForm" v-model="valid" lazy-validation>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.name" label="name"
                                                        :rules="[rules.required]"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.arabicName" label="arabic name"
                                                        :rules="[rules.required]"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.userName" label="user name"
                                                        :rules="[rules.required]"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.eMail" label="E-Mail"
                                                        :rules="[rules.required, rules.emailRule]"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-text-field id="password" class="green-input"
                                                        v-model="editedItem.password" label="Password"
                                                        :rules="[rules.required, rules.password]"
                                                        :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                        @click:append="() => (value = !value)"
                                                        :type="value ? 'text' : 'password'" />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field id="confirmPassword" class="green-input"
                                                        v-model="editedItem.confirmPassword" label="confirm Password"
                                                        :rules="[rules.required, rules.confirmpassword]"
                                                        :append-icon="valueconfirmed ? 'mdi-eye' : 'mdi-eye-off'"
                                                        @click:append="() => (valueconfirmed = !valueconfirmed)"
                                                        :type="valueconfirmed ? 'text' : 'password'" />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.mobile" label="mobile"
                                                        :rules="[rules.required]"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-select v-model="editedItem.gender" :items="options"
                                                        :rules="[rules.required]" label="gender">
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                    <v-row>
                                        <v-progress-linear :active="editUserDetailsLoading" color="green"
                                            :indeterminate="editUserDetailsLoading" absolute bottom height="15">
                                            Loading...
                                        </v-progress-linear>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="green darken-1" text @click="close"> Cancel</v-btn>
                                    <v-btn color="green darken-1" :disabled="!valid || editUserDetailsLoading" text @click="validate">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:[`item.joinDate`]="{ item }">
                        {{ getDate(new Date(item.joinDate)) }}
                    </template>
                    <template v-slot:[`item.gender`]="{ item }">
                        <div v-if="item.gender == 1">Male</div>
                        <div v-if="item.gender == 2">Female</div>
                    </template>
                    <template v-slot:[`item.password`]="{ item }">
                        <template v-for="(x, index) in item.password">
                            <span :key="index">*</span>
                        </template>
                    </template>
                    <template v-slot:[`item.Action`]="{ item }">
                        <v-icon small class="mr-2" @click="editUserDetails(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small class="mr-2" @click="ConfirmUpdateStatus(item)" color="red" v-if="item.isActive == 0">
                            mdi-brightness-1
                        </v-icon>
                        <v-icon small class="mr-2" @click="ConfirmUpdateStatus(item)" color="green"
                            v-if="item.isActive == 1">
                            mdi-brightness-1
                        </v-icon>
                        <v-icon small class="mr-2" @click="confirmDelete(item)" color="red">
                            mdi-delete-empty
                        </v-icon>
                    </template>
                    <template v-slot:[`item.User_Rights`]="{ item }">
                        <v-icon small class="mr-2" @click="editUserRoles(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>

        <v-dialog v-model="addDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add New user</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="AddUserInfoForm" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.name" label="name"
                                        :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.arabicName" label="arabic name"
                                        :rules="[rules.required]"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.userName" label="user name"
                                        :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.eMail" label="E-Mail"
                                        :rules="[rules.required, rules.emailRule]"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field id="password" class="green-input" v-model="editedItem.password"
                                        label="Password" :rules="[rules.required, rules.password]"
                                        :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="() => (value = !value)" :type="value ? 'text' : 'password'" />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field id="confirmPassword" class="green-input"
                                        v-model="editedItem.confirmPassword" label="confirm Password"
                                        :rules="[rules.required, rules.confirmpassword]"
                                        :append-icon="valueconfirmed ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="() => (valueconfirmed = !valueconfirmed)"
                                        :type="valueconfirmed ? 'text' : 'password'" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.mobile" label="mobile"
                                        :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-select v-model="editedItem.gender" :items="options" :rules="[rules.required]"
                                        label="gender">
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <v-row>
                        <v-progress-linear :active="addUserDetailsLoading" color="green"
                            :indeterminate="addUserDetailsLoading" absolute bottom height="15">
                            Loading...
                        </v-progress-linear>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="addDialog = false"> Cancel</v-btn>
                    <v-btn color="green darken-1" :disabled="!valid" text @click="validateAdd">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="approveReqDialog" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">ِAre you sure you want to Activate/DeActivate user? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="updateUserStatus">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="approveReqDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteConfirm" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">ِAre you sure you sure you want to delete use ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteUser">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="deleteConfirm = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
                <v-progress-linear :active="DeleteConfirmloading" color="green" :indeterminate="DeleteConfirmloading"
                    absolute bottom height="15">
                    Loading...
                </v-progress-linear>
            </v-card>
        </v-dialog>

        <div class="text-center">
            <v-snackbar v-model="snackbar" timeout="3000">
                {{ alertMsg }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <div class="text-center">
            <v-snackbar v-model="errorsnackbar" timeout="3000">
                {{ alertMsg }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="errorsnackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>


    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Password from 'vue-password-strength-meter'
export default {
    mixins: [validationMixin],
    components: {
        // Password
    },
    computed: {

    },
    data() {
        return {
            addUserDetailsLoading: false,
            value: false,
            valueconfirmed: false,
            alertMsg: '',
            snackbar: false,
            model: -1,
            loading: false,
            approveReqDialog: false,
            userRolesDialog: false,
            deleteConfirm: false,
            errorsnackbar: false,
            search: '',
            valid: false,
            Editdialog: false,
            addDialog: false,
            errorExists: false,
            error: '',
            length: 0,
            editUserDetailsLoading: false,
            DeleteConfirmloading: false,
            UserRightsFields: [
                "Window",
                "Update",
                "Open",
            ],
            UserRightsItems: [
                { Window: 'window1', Update: true, Open: true },
                { Window: 'window2', Update: false, Open: true },
                { Window: 'window3', Update: false, Open: false }
            ],
            rules: {
                required: value => !!value || 'Required Field',
                emailRule: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'E-mail must be valid',
                confirmpassword: value => value === this.editedItem.password || 'two passwords do not match',
                password: value => {
                    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/;
                    return (
                        pattern.test(value) ||
                        "Min. 8 characters with at least one capital letter, a number and a special character."
                    );
                },

            },
            newFields: [
                { value: 'name', text: this.$t("users.grid.name"), width: 120 },
                { value: 'arabicName', text: this.$t("users.grid.arabicName"), width: 120 },
                { value: 'userName', text: this.$t("users.grid.userName") },
                { value: 'password', text: this.$t("users.grid.password") },
                { value: 'mobile', text: this.$t("users.grid.mobile") },
                { value: 'gender', text: this.$t("users.grid.gender") },
                { value: 'joinDate', text: this.$t("users.grid.joinDate") },
                { value: 'eMail', text: this.$t("users.grid.eMail") },
                { value: 'notes', text: this.$t("users.grid.notes") },
                { value: 'User_Rights', text: this.$t("users.grid.User_Rights"), width: 120 },
                { value: 'Action', text: this.$t("users.grid.action"), width: 120 }],
            items: [],
            selected: null,
            options: [
                { value: 1, text: 'Male' },
                { value: 2, text: 'Female' },
            ],
            UserRolesList: [
                { value: "SuperAdmin", text: this.$t("users.rolesList.superAdmin") },
                { value: "CustomerSupport", text: this.$t("users.rolesList.customerSupport") },
                { value: "SystemAdmin", text: this.$t("users.rolesList.systemAdmin") },
                { value: "User", text: this.$t("users.rolesList.user") },
            ],
            currentEditedJobIndex: null,
            form: {
                name: null,
                arabicName: null,
                userName: null,
                password: null,
                confirmPassword: null,
                mobile: null,
                gender: null,
                joinDate: null,
                eMail: null,
                notes: null,
                selectedRoles: []
            },
            editedItem: {
                name: null,
                arabicName: null,
                userName: null,
                password: null,
                confirmPassword: null,
                mobile: null,
                gender: null,
                joinDate: null,
                eMail: null,
                notes: null,
                selectedRoles: []
            },
            rolesDialogError: false
        }
    },
    validations: {
        form: {
            name: {
                required,
            },
            arabicName: {
                required,
            },
            userName: {
                required,
            },
            password: {
                // required
                required: {
                    check() {
                        if (this.form.password == null || this.form.password.length < 8) {
                            return false;
                        }
                        return true;
                    }
                }
            },
            confirmPassword: {
                required: {
                    check() {
                        if (this.form.confirmPassword == null ||
                            this.form.password !== this.form.confirmPassword) {
                            return false;
                        }
                        return true;
                    }
                }
            },
            mobile: {
                required,
            },
            gender: {
                required,
            },
            joinDate: {
                required,
            },
            eMail: {
                required: {
                    check() {
                        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(String(this.form.eMail).toLowerCase());
                    }
                },
            },
            selectedRoles: {
                required,
            },

        }
    },
    methods: {
        close() {
            this.Editdialog = false;
        },
        getUsersList() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/Account`,
            }).then((response) => {
                if (response.data.status == true) {
                    this.items = response.data.users;
                    console.log("this.items");
                    console.log(this.items);
                }
                else {
                    this.errorExists = true;
                    this.error = response.data.message
                }
            });

        },
        ConfirmUpdateStatus(item) {
            this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)
            this.approveReqDialog = true;
        },
        getVal(val) {
            if (this.form.selectedRoles.indexOf(val) > -1) {
                return true;
            }
            else {
                return false;
            }

        },
        confirmDelete(item) {
            this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)
            this.deleteConfirm = true;
        },
        deleteUser() {
            this.DeleteConfirmloading = true;
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/Account`,
                params: {
                    UserId: this.editedItem.id,
                }
            }).then(() => {

                this.$nextTick(() => {
                    this.DeleteConfirmloading = false;
                    // this.items[this.editedIndex];
                    this.alertMsg = "User Roles updated successfully"
                    this.deleteConfirm = false;
                    this.snackbar = true;
                    this.getUsersList();
                })
            });

        },
        updateUserStatus() {



            if (Boolean(this.editedItem.isActive) == false) {
                this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/Account/UserStatus?UserName=${this.editedItem.userName}&status=${true}&id=${this.editedItem.id}`,
                }).then(() => {
                    this.items[this.editedIndex].isActive = true;

                    // this.editedItem = null;
                    // this.this.editedIndex = -1;
                    // this.approveReqDialog = false;

                    // this.$refs.usersList.refresh();

                });
                this.approveReqDialog = false;
            }
            if (Boolean(this.editedItem.isActive) == true) {
                this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/Account/UserStatus?UserName=${this.editedItem.userName}&status=${false}&id=${this.editedItem.id}`,
                }).then(() => {
                    this.items[this.editedIndex].isActive = false;

                    // this.editedItem = null;
                    // this.this.editedIndex = -1;
                    // this.approveReqDialog = false;


                    // this.$refs.usersList.refresh();
                });

                this.approveReqDialog = false;
            }



        },
        getVariant(item, activeateUser) {
            if (activeateUser == 1) {
                if (Boolean(item.isActive) == true)
                    return 'primary'
                else
                    return 'secondary'
            }
            else {
                if (Boolean(item.isActive) == true)
                    return 'secondary'
                else
                    return 'primary'
            }

        },
        clear() {
            this.editedItem.name = '';
            this.editedItem.arabicName = '';
            this.editedItem.userName = '';
            this.editedItem.password = '';
            this.editedItem.confirmPassword = '';
            this.editedItem.mobile = '';
            this.editedItem.gender = '';
            this.editedItem.joinDate = '';
            this.editedItem.eMail = '';
            this.editedItem.notes = '';
            this.editedItem.selectedRoles = [];
        },
        editUserDetails(item) {
            this.clear();
            this.editedItem = Object.assign({}, item);
            this.editedItem.selectedRoles = item.userRoles;
            this.currentEditedJobIndex = this.items.indexOf(item);
            this.Editdialog = true;
        },
        addNewUser() {
            this.clear();
            this.addDialog = true;
        },
        editUserRoles(item) {
            this.clear();
            this.editedItem = Object.assign({}, item);
            this.editedItem.selectedRoles = item.userRoles;
            this.currentEditedJobIndex = this.items.indexOf(item);
            this.userRolesDialog = true;

        },
        UpdateUserRoles() {
            this.loading = true;
            if (this.editedItem.selectedRoles.length == 0) {
                this.rolesDialogError = true;
            }
            else {
                this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/Account/UserRoles`,
                    data: {
                        UserName: this.editedItem.userName,
                        UserRoles: this.editedItem.selectedRoles
                    }
                }).then(() => {
                    this.userRolesDialog = false;
                    this.loading = false;
                    this.$nextTick(() => {
                        this.items[this.currentEditedJobIndex].userRoles = this.editedItem.selectedRoles;
                        this.alertMsg = "User Roles updated successfully"
                        this.snackbar = true;
                    })
                });

                // this.rolesDialogError = false;
                // this.$bvModal.hide('EditUserRoles');
            }

        },
        editUserInfo(item, index) {
            this.form.name = item.name;
            this.form.arabicName = item.arabicName;
            this.form.userName = item.userName;
            this.form.password = item.password;
            this.form.confirmPassword = item.password;
            this.form.gender = item.gender
            this.form.joinDate = item.joinDate;
            this.form.eMail = item.eMail;
            this.form.mobile = item.mobile;
            this.form.notes = item.notes;
            this.form.selectedRoles = item.userRoles;
            this.$bvModal.show('EditSystemUser');
            this.currentEditedJobIndex = index;
        },
        UpdateSystemUser() {
            this.editUserDetailsLoading = true;
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/Account/UserRegisteration`,
                data: {
                    Name: this.editedItem.name,
                    ArabicName: this.editedItem.arabicName,
                    UserName: this.editedItem.userName,
                    Password: this.editedItem.password,
                    Gender: this.editedItem.gender,
                    // JoinDate: this.editedItem.joinDate,
                    Email: this.editedItem.eMail,
                    PhoneNumber: this.editedItem.mobile,
                    Notes: this.editedItem.notes == null ? '' : this.editedItem.notes,
                    UserRoles: this.editedItem.selectedRoles
                }
            }).then((response) => {
                if (response.data.status == true) {

                    Object.assign(this.items[this.currentEditedJobIndex], this.editedItem)
                    this.close()
                    this.editUserDetailsLoading = false;
                    this.alertMsg = "User Data updated successfully"
                    this.snackbar = true;
                    this.getUsersList();

                }
                else {
                    this.alertMsg = response.data.message
                    this.errorsnackbar = true;
                    this.editUserDetailsLoading = false;
                }

            });
        },

        addSystemUser() {
            this.addUserDetailsLoading = true;
            let data = {
                Name: this.editedItem.name,
                ArabicName: this.editedItem.arabicName,
                UserName: this.editedItem.userName,
                Password: this.editedItem.password,
                Gender: this.editedItem.gender,
                JoinDate: new Date(),
                Email: this.editedItem.eMail,
                PhoneNumber: this.editedItem.mobile,
                Notes: this.editedItem.notes == null ? '' : this.editedItem.notes,
                UserRoles: []
            };
            this.$axios({
                method: 'post',
                url: `${this.$baseURL}/Account/UserRegisteration`,
                data: data
            }).then((response) => {
                if (response.data.status == true) {

                    this.addDialog = false
                    this.addUserDetailsLoading = false;
                    this.alertMsg = "User added successfully"
                    this.snackbar = true;
                    this.getUsersList();
                }
                else {
                    this.alertMsg = response.data.message
                    this.errorsnackbar = true;
                    this.addUserDetailsLoading = false;
                }

            });
        },

        getDate(date) {
            let today = new Date();
            if (date != null) {
                today = date;
            }
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            return dd + '/' + mm + '/' + yyyy;
        },
        async addUser() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            try {

                let response = await this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/Account/UserRegisteration`,
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        Name: this.form.name,
                        ArabicName: this.form.arabicName,
                        UserName: this.form.userName,
                        Password: this.form.password,
                        Gender: this.form.gender,
                        JoinDate: this.form.joinDate,
                        Email: this.form.eMail,
                        PhoneNumber: this.form.mobile,
                        Notes: this.form.notes == null ? '' : this.form.notes,
                        UserRoles: this.form.selectedRoles
                    }
                });

                if (JSON.parse(response.data.status) == true) {
                    this.$bvModal.msgBoxOk("User Added Successfully", {
                        title: 'success',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                    this.$bvModal.hide('NewUser');
                    this.getUsersList();
                }
                else {
                    console.log(response.data.message);
                    this.$bvModal.msgBoxOk(response.data.message, {
                        title: 'error',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    });
                }



            }
            catch (err) {
                console.log(err);
            }

        },
        onReset() {
            this.form = {
                name: null,
                arabicName: null,
                userName: null,
                password: null,
                confirmPassword: null,
                mobile: null,
                gender: null,
                joinDate: null,
                eMail: null,
                notes: null,
                selectedRoles: []
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        validate() {
            if (this.$refs.EditUserInfoForm.validate()) {
                this.UpdateSystemUser();
            }
        },
        validateAdd() {
            if (this.$refs.AddUserInfoForm.validate()) {
                this.addSystemUser();
            }
        }

    },
    mounted() {
        this.getUsersList();

    }
}
</script>

<style scoped>
.mandatory {
    color: red
}

#RolesItemsList {
    background: #D1E4FF;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    padding: 10px 20px;
}

#RolesItemsList ul {
    padding: 0;
    list-style-type: none;
}

#RolesItemsList li {
    margin-right: 10px;
    margin-top: 10px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.7);
}

/* .price{
        font-weight: bold;
        color: #860CE8;
        display: block;
    } */
</style>